import { linkPlaceholders } from '@trackmatic/yard-ui-common';

const routing = {
  loadExecutionView: `/load_execution/${linkPlaceholders.loadId}`,
  dashCamView: `/dashcam`,
  entityView: `/organisations/${linkPlaceholders.orgId}/entities/${linkPlaceholders.linkEntityId}`,
  surfsightDownload: `/surfsight`,
};

export { routing };
